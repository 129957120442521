<template>
    <v-parallax :height="$vuetify.breakpoint.smAndDown ? 700 : 300"
      src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
      >
      <v-container fill-height>
        <v-row class="mx-auto">
          <v-col v-for="(item, i) in links" :key="i" :href="item.href">
            <div class="text-center">
              <base-btn :href="item.href" large square target="_blank">
                <v-icon large v-text="item.icon" />
              </base-btn>
            </div>
          </v-col>
          <v-col >
            <div class="text-center">
              <base-btn href="https://www.tiktok.com/@center.qamar" large square target="_blank">
              <v-img max-height="70" max-width="70"
              :src="require('@/assets/tik3.png')"></v-img></base-btn>
            </div>
          </v-col>
         
           
        </v-row>
      </v-container>
    </v-parallax>
</template>
<script>


export default {
  name: 'Parallax',
  data: () => ({
    links: [
      {
        href: 'https://www.facebook.com/alqamar.cen',
        icon: 'fa-facebook-square',
        color: '#3b5998 '
      },
      {
        href: 'https://www.instagram.com/alqamar_cen',
        icon: 'fa-instagram',
        color: '#fb3958'
      },
      {
        href: 'https://t.me/alqamar_cen',
        icon: 'fa-telegram',
        color: '#2AABEE'
      },
      {
        href: 'https://www.youtube.com/channel/UCpN36-3mIoIGzJjxBZJ7HKw',
        icon: 'fa-youtube',
        color: '#FF0000'
      },
    ]

  }),

}
</script>
  